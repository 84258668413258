/* Font classes */
.ui-font-weight-thin {
  font-weight: 400;
}
.ui-font-weight-normal {
  font-weight: 500;
}
.ui-font-weight-thick {
  font-weight: 700;
}
.ui-font-weight-bold {
  font-weight: 800;
}

.ui-font-xsmall {
  font-size: 0.75rem;
}
.ui-font-small {
  font-size: 0.875rem;
}
.ui-font-normal {
  font-size: 1rem;
}
.ui-font-large {
  font-size: 1.125rem;
}
.ui-font-xlarge {
  font-size: 1.375rem;
}
.ui-font-jumbo {
  font-size: 1.875rem;
}
.ui-font-xjumbo {
  font-size: 3.375rem;
}

/* Common classes */
.ui-radius-common {
  border-radius: var(--item-size-5);
}
.ui-radius-circle {
  border-radius: 50%;
}

/* Other */
.ui-opacity-1 {
  opacity: 0.1;
}
.ui-opacity-2 {
  opacity: 0.2;
}
.ui-opacity-3 {
  opacity: 0.3;
}
.ui-opacity-4 {
  opacity: 0.4;
}
.ui-opacity-5 {
  opacity: 0.5;
}
.ui-opacity-6 {
  opacity: 0.6;
}
.ui-opacity-7 {
  opacity: var(--opacity-disabled);
}
.ui-opacity-8 {
  opacity: 0.8;
}
.ui-opacity-9 {
  opacity: 0.9;
}

.ui-text-underline {
  text-decoration: underline;
}

.ui-text-cap {
  text-transform: capitalize;
}
.ui-text-lower {
  text-transform: lowercase;
}
.ui-text-uppper {
  text-transform: uppercase;
}

.ui-text-left {
  text-align: left;
}
.ui-text-center {
  text-align: center;
}
.ui-text-right {
  text-align: right;
}

.content-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
